import './App.css';
import { GiHamburgerMenu } from "react-icons/gi";

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <nav id='navi-bar'>
          <div id='navi-container'>
            <h1 id='logo-text'>ProVertical</h1>
            <div id='hamburger'><GiHamburgerMenu /></div>
            <ul id='navi-links'>
              <li><a href='#home'>Home</a></li>
              <li><a href='#about'>About</a></li>
              <li><a href='#services'>Services</a></li>
              <li><a href='#gallery'>Gallery</a></li>
            </ul>
          </div>
        </nav>
      </header>
      <div id='home'>
        <div id='home-container'>
          <h1 id='welcome-msg'>
            Welcome to <span>ProVertical</span> Storage Systems
          </h1>
          <h4 id='welcome-paragraph-first'>As an authorized Hänel Storage Systems channel partner, we are responsible for all project management, sales, installation, servce and support for the entire Hänel product line.</h4>

          <h4 id='welcome-paragraph-second'>We specialize in turn key solutions for a broad spectrum of customers, covering a wide range of applications.</h4>
        </div>
      </div>
      <div id='about'>
        <div id='about-container'>
          <h1 id='about-title'> Vertical Lift Technology</h1>
          <h3 className='about-header'>
            Why go with a Hänel storage solution?
          </h3>
          <ul id='about-list'>
            <li><span className='list-title'>Save space:</span> Conventional storage methods require a lot of space. With their compact design and utilization of room height, Hänel systems offer maximum storage capacity in a given space.</li>
            <li><span className='list-title'>Save time:</span> Operating conventional storage systems costs users time. Hänel storage systems result in substantial time savings by making horizontal distance vertical!</li>
            <li><span className='list-title'>Ergonomics:</span> With a traditional storage solution, users have to bend, stretch or even climb ladders in order to retrieve stored items. With a Hänel system, the requested item is brought automatically to a comfortable height at the touch of a button.</li>
            <li><span className='list-title'>Safety:</span> Hänel systems protect all parts from dirt, damage and unauthorized access.</li>
            <li><span className='list-title'>Flexibility:</span> With a Hänel storage system, you can expand or alter your storage capacity. Units can even be combined to span multiple floors or be expanded step by step to meet the growing needs of your business.</li>
            <li><span className='list-title'>Networking:</span> By networking their intelligent microprocessor controllers and offering practice-oriented software solutions, Hänel creates a perfect logistics system that is precisely to your requirements.</li>
          </ul>
          <h3 className='about-header'>
            Where can a Hänel storage solution help?
          </h3>
          <div className='about-paragraph-grid'>
            <h4>
              Office and Administrative Applications
            </h4>

            <h4>
              Industrial Applications
            </h4>

            <ul>
              <li>File & document storage</li>
              <li>Vertical or lateral standing folders</li>
              <li>Binders</li>
              <li>Sensitive & critical documents</li>
            </ul>

            <ul>
              <li>General part storage</li>
              <li>Test fixtures</li>
              <li>Templates</li>
              <li>Tools and Dies</li>
              <li>Raw Materials or ingredients</li>
            </ul>
          </div>
          <p>
            Hänel systems can be wheelchair accessible and be equipped to deal with heavy load capacities.
          </p>
          <h3 className='about-header'>
            Who could be helped by a Hänel storage solution?
          </h3>
          <ul>
            <li>Government (Military and Police)</li>
            <li>Insurance Offices</li>
            <li>Accounting or Law Firms</li>
            <li>Hospitals</li>
            <li>Taxation Offices</li>
            <li>Aerospace</li>
            <li>Automotive</li>
            <li>Electronics</li>
            <li>Pharmacies</li>
          </ul>

          <div id='youTube-videos'>
            <div id='video-one'>
              <iframe title='Hanel Office Rotomat - ESS Group' src="https://www.youtube.com/embed/u88ampEnz1A"></iframe>
            </div>
            <div id='video-two'>
              <iframe title='Hanel Rotomat Industrial Carousels - ESS Group' src="https://www.youtube.com/embed/oHDBMwRWYVA"></iframe>
            </div>
          </div>

        </div>
      </div>


      <div id='services'>
        <div id='services-container'>
          <h1>Our Services</h1>
          <div className='card-container-one'>
            <div className='large-card'>
              <h3>Hänel Vertical Lift Technology</h3>
              <ul>
                <li>Authorized Hänel Channel Partner</li>
                <li>Consultation and Project Management</li>
                <li>Authorized Hänel installation, Service and Support</li>
              </ul>
            </div>
            <div className='large-card'>
              <h3>SSI Schaefer Systems International</h3>
              <ul>
                <li>Authorized SSI Channel Partner</li>
                <li>Specialty Containers</li>
                <li>Modular Shelving</li>
                <li>Flow Racking, Point of Use Assemblies, Mobile Shelving Units</li>
                <li>Mezzanines</li>
                <li>Custom Made Shipping / Recieving Areas</li>
              </ul>
            </div>
            <div className='large-card'>
              <h3>Prothermoform Vacuum Forming</h3>
              <ul>
                <li>Authorized Prothermoform Channel Partner</li>
                <li>Vacuum Forming Applications</li>
                <li>Specialty and Custom Containers / Part Containment Systems</li>
                <li>Tooling Trays and Cradles</li>
                <li>Part Protection Systems</li>
                <li>Modular Tray Inserts for Hänel Lean Lift Pans</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id='gallery'>
        <div id='gallery-container'>
          <h1 id='gallery-title'>
            Check Out Our Gallery
          </h1>
          <div className='card-container-three'>
            <div className='small-card'>
              <p>Gallery Image #1</p>
            </div>

            <div className='small-card'>
              <p>Gallery Image #2</p>
            </div>

            <div className='small-card'>
              <p>Gallery Image #3</p>
            </div>

            <div className='small-card'>
              <p>Gallery Image #4</p>
            </div>

            <div className='small-card'>
              <p>Gallery Image #5</p>
            </div>

            <div className='small-card'>
              <p>Gallery Image #6</p>
            </div>
          </div>

        </div>
      </div>



      <div id='foot'>
        <div id='footer-container'>
          <h1 id='contact-title'>
            Contact Us
          </h1>
          <div className='card-container-two'>
            <ul className='contact-card'>
              <li><h2 id='contact-head'>Office and Warehouse</h2></li>
              <li><p id='address'>
                51 Elgin Street, Grimsby, ON
                L3M 4G7
              </p></li>
            </ul>
            <ul className='contact-card'>
              <li><h2 id='contact-sales'>Sales Department:</h2></li>
              <li><p id='sales-tele'>
                T: 905-635-6682
              </p>
              </li>
              <li><p id='sales-email'>
                E: sales@provertical.ca
              </p></li>
            </ul>
            <ul className='contact-card'>
              <li><h2 id='contact-service'>Service Department:</h2></li>
              <li><p id='services-tele'>
                T: 905-975-8496
              </p>
              </li><li>
                <p id='services-email'>
                  E: service@provertical.ca
                </p></li>
            </ul>
          </div>
          <ul id='footer-end'>
            <li>Authorized Hänel Storage Systems Channel Partner</li>
            <li>ProVertical © 2023</li>
            <li>Site developed by: <a href='https://hire.damonmorgan.net'>Damon M</a></li>
          </ul>

        </div>
      </div>
    </div>



  );
}

export default App;
